<template>
  <v-list nav dense>
    <template v-for="(group, a) in menu">
      <v-list-item-group v-bind:key="a" v-if="group.options.show">
        <v-subheader>{{ group.name }}</v-subheader>

        <template v-for="(item, b) in group.items">
          <v-list-item v-bind:key="b" v-if="item.options.show" :to="item.route">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </template>
      </v-list-item-group>
    </template>
  </v-list>
</template>

<script lang="ts">
import Vue from 'vue';
import { createInstance } from '@/services/sideMenuService';

export default Vue.extend({
  data() {
    return {
      group: null
    };
  },

  computed: {
    menu() {
      return createInstance();
    }
  }
});
</script>

<style lang="sss" scoped></style>
