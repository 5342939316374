import Vue from 'vue';
import Router, {
  Route, NavigationGuardNext, RouteConfig, RawLocation
} from 'vue-router';

import Toastr from 'toastr';
import { actions } from '@/Pages/CustomerService/SessionCustomersStore';
import { Customer } from '@/types/customer-api-types';
import AuthService, { Roles } from '@/services/authService';

export const ROOT = '/my-portal/';

const getFirstValueFromQueryParam = (
  queryParam: string | null | (string | null)[]
): string | undefined => {
  if (queryParam === null || typeof queryParam === 'undefined') {
    return undefined;
  }

  if (Array.isArray(queryParam)) {
    return getFirstValueFromQueryParam(queryParam[0]);
  }

  return queryParam;
};

Vue.use(Router);

// const isProd = process.env.NODE_ENV === 'production';
const routes: RouteConfig[] = [];

// root redirect
routes.push({
  path: '/',
  redirect: `${ROOT}home`
});

// login
routes.push({
  path: `${ROOT}register`,
  name: 'register',
  meta: {
    layout: 'userpages',
    anonymous: true
  },
  component: () => import('../Pages/Register.vue')
});

// logout
routes.push({
  path: `${ROOT}logout`,
  name: 'logout',
  props: (route) => ({
    isUserAction: !!getFirstValueFromQueryParam(route.query.uaction) || false
  }),
  component: () => import('../Pages/Logout.vue')
});

// home
routes.push({
  path: `${ROOT}home`,
  name: 'home',
  component: () => import('../Pages/MyPortal/Home.vue'),
  meta: {
    requiresAuth: true
  }
});

// sessions
routes.push({
  path: `${ROOT}sessions`,
  name: 'sessions',
  component: async () => import('../Pages/MyPortal/Sessions.vue'),
  meta: {
    requiresAuth: true
  }
});

// processing portal
routes.push(
  // home
  {
    path: `${ROOT}processing/home`,
    name: 'processing',
    component: async () => import('../Pages/Processing/ProcessingHome.vue'),
    meta: {
      requiresAuth: true
    }
  }
);

// customer service portal
routes.push(
  // customer service portal home
  {
    path: `${ROOT}customer-service/home`,
    name: 'customer-service',
    component: async () => import('../Pages/CustomerService/CustomerServiceHome.vue'),
    meta: {
      requiresAuth: true
    }
  }
);

// settings
routes.push({
  path: `${ROOT}settings`,
  name: 'settings',
  component: async () => import('@/Pages/Settings/Settings.vue'),
  meta: {
    requiresAuth: true
  }
});

routes.push(
  // // admin portal home
  {
    path: `${ROOT}admin/home`,
    name: 'admin',
    component: async () => import('../Pages/AdminService/CTAdminHome.vue'),
    meta: {
      requiresAuth: true
    }
  }
);

// admin - user search
routes.push({
  path: `${ROOT}admin/users`,
  name: 'admin-user-search',
  component: () => import('../Pages/AdminService/CTUserSearch.vue'),
  meta: { requiredRole: Roles.ADMIN, requiresAuth: true }
});

// customer-service
export type TCustomerRouteInfo = {
  other: Partial<Record<string, never>>;
  customer: Partial<Customer>;
};

routes.push(
  // customers/search
  {
    path: `${ROOT}customer-service/customers/search`,
    name: 'customer-service-customers-search',
    component: () => import('../Pages/CustomerService/CustomerSearch.vue'),
    props: (route) => ({ shouldClear: route.query.c === '1' }),
    meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
  },

  // customers/[...]
  {
    path: `${ROOT}customer-service/customers/:id?`,

    beforeEnter(to, from, next) {
      // if customerid is not specified, try to use a customer id from the past
      if (!to.params.id || Number.isNaN(Number(to.params.id))) {
        const loscid = actions.fetchLastOpenedSessionCustomerIdFromSessionStorage();

        if (typeof loscid === 'number') {
          return next({
            name: 'customer',
            params: { ...to.params, id: loscid.toString() },
            replace: true
          });
        }

        return next();
      }

      return next();
    },

    props: (route) => {
      const parseAsNumber = (
        obj: undefined | null | string | (string | null)[],
        defaultValue: number | undefined = undefined
      ): number | undefined => {
        if (!obj) {
          return undefined;
        }

        if (Array.isArray(obj)) {
          return parseAsNumber(obj[0]);
        }

        return Number.isNaN(parseInt(obj, 10)) ? defaultValue : parseInt(obj, 10);
      };

      const routeCustomerInfo: TCustomerRouteInfo = {
        other: {},

        customer: {
          id: parseAsNumber(route.params.id),
          accountId: parseAsNumber(route.query.accountId)
        }
      };

      return { routeCustomerInfo };
    },

    component: () => import('../Pages/CustomerService/Customers.vue'),

    meta: { requiredRole: 'customer_service', requiresAuth: true },

    children: [
      {
        path: 'account',
        name: 'customer-account',

        component: () => import('../Pages/CustomerService/Customer/Account.vue'),

        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      },
      {
        path: 'profile',
        name: 'customer-profile',
        component: () => import('../Pages/CustomerService/Customer/Profile.vue'),
        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      },
      {
        path: 'cards',
        name: 'customer-cards',
        component: () => import('../Pages/CustomerService/Customer/Cards.vue'),
        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      },
      {
        path: 'baghistory',
        name: 'customer-baghistory',
        component: () => import('../Pages/CustomerService/Customer/BagHistory.vue'),
        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      },
      // {
      //   path: 'security',
      //   name: 'customer-security',
      //   component: () => import('../Pages/CustomerService/Customer/Security.vue'),
      //   meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true },
      // },
      {
        path: 'transactions',
        name: 'customer-transactions',
        component: () => import('../Pages/CustomerService/Customer/Transactions.vue'),
        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      },
      // {
      //   path: "subscriptions",
      //   name: "customer-subscriptions",
      //   component: () =>
      //     import("../Pages/CustomerService/Customer/Subscriptions.vue"),
      //   meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true },
      // },
      {
        path: 'adjustments',
        name: 'customer-adjustments',
        component: () => import('../Pages/CustomerService/Customer/AccountAdjustmentRequests.vue'),
        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      },
      {
        path: 'notes',
        name: 'customer-notes',
        component: () => import('../Pages/CustomerService/Customer/Notes.vue'),
        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      },
      {
        path: '/',
        name: 'customer',
        beforeEnter(to, from, next) {
          if (!to.params.id || Number.isNaN(to.params.id)) {
            // we were not provided a customer id, so continue to main component
            // and remove any other url params we might have
            return next({ params: {}, replace: true });
          }

          return next();
        },
        meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
      }
    ]
  },
  {
    path: `${ROOT}customer-service/account-adjustment-requests`,
    name: 'customer-service-account-adjustment-requests',
    component: () => import('../Pages/CustomerService/AccountAdjustmentRequests.vue'),
    meta: { requiredRole: Roles.RETAIL_CLERK, requiresAuth: true }
  }
);

// Retail
routes.push(
  // home
  {
    path: `${ROOT}retail/home`,
    name: 'retail',
    component: () => import('../Pages/Retail/Home.vue'),
    meta: { requiredRole: Roles.RETAIL_CLERK }
  },
  {
    path: `${ROOT}retail/depots`,
    name: 'retail-depot-table',
    component: () => import('../Pages/Retail/Depots.vue'),
    meta: { requiredRole: Roles.RETAIL_CLERK }
  }
);

routes.push({
  path: '*',
  name: 'notFound',
  meta: { requiresAuth: true }
});

const router = new Router({
  scrollBehavior() {
    return window.scrollTo({ top: 0, behavior: 'smooth' });
  },
  mode: 'hash',
  routes
});

const routeRoleCheck = (to: Route, from: Route, next: NavigationGuardNext) => {
  // get user roles
  const { roles } = AuthService.getAuthenticatedUser();

  // If the route we are navigating towards has a required role and
  // the current user does not have this role
  if (to.meta?.requiredRole && !roles.includes(to.meta.requiredRole)) {
    // display a message
    Toastr.error('Access denied.');

    // navigate to the home page
    return next({ path: `${ROOT}home` });
  }

  // otherwise user has required roles or there is no defined required role
  // and is to continue
  return next();
};

// const encodeRedirect = (route: Route): string => btoa(JSON.stringify({ path: route.path, query: route.query }))
//   .replace('+', '-')
//   .replace('/', '_')
//   .replace(/=+$/, '');

export const decodeRedirect = (encoded: string): RawLocation => {
  let encodedNormalized = encoded.replace('-', '+').replace('_', '/');

  while (encodedNormalized.length % 4) {
    encodedNormalized += '=';
  }

  return JSON.parse(atob(encodedNormalized));
};

// const generateRedirectBackTo = (to: Route, from: Route): string | undefined => {
//   const pathsToAvoid = ['home', 'register', 'logout', from?.name].filter(
//     Boolean
//   ) as string[];

//   if (to.name && pathsToAvoid.includes(to.name)) {
//     return undefined;
//   }

//   return encodeRedirect(to);
// };

router.beforeEach(async (to: Route, from: Route, next: NavigationGuardNext) => {
  const keycloak = Vue.prototype.$keycloak;

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.name === 'notFound') {
      let redirect: RawLocation = { name: 'home' };
      if (to.name === 'notFound') {
        next(redirect);
      }

      const { redirectBackTo } = to.query;

      if (typeof redirectBackTo === 'string') {
        try {
          redirect = decodeRedirect(redirectBackTo);
        } catch (error) {
          console.error(error);
        }
      }

      if (!keycloak.authenticated) {
        // Try refreshing
        try {
          AuthService.refresh(keycloak.token, keycloak.idToken, keycloak.authenticated);
        } catch {
          next();
          return;
        }
      }

      if (keycloak.authenticated) {
        next(redirect);
      } else {
        next();
      }

      return;
    }

    if (to.matched.some((record) => record.meta.anonymous)) {
      next();
      return;
    }

    if (keycloak.authenticated) {
      routeRoleCheck(to, from, next);
    } else {
      const loginUrl = keycloak.createLoginUrl();
      window.location.replace(loginUrl);
    }
  } else {
    if (keycloak.authenticated && to.name === 'register') {
      const redirect: RawLocation = { name: 'home' };
      next(redirect);
    }

    next();
  }
});

export default router;
